<template>
  <div class="detected">
    <div class="modal__body detected__body">
      <div class="detected__title">
        {{ title }}
      </div>
      <div class="detected__text">
        {{ text }}
      </div>
      <div class="detected__divider" />
    </div>
    <div class="detected__footer">
      <button
        class="button button_secondary button_md detected__capitalized-button"
        @click="onClose"
      >
        Exit
      </button>
    </div>
  </div>
</template>

<script>
import {
  MSG_SESSION_RECORDING_ERROR_TITLE,
  MSG_SESSION_RECORDING_ERROR_TEXT,
  ROUTE_PROFILE_HISTORY
} from "@/constants";
import { saveLog } from "@/modules/logger";
import { getStorage } from "@/modules/localStorage";
import { reloadPage } from "@/utils/system";
import { deregisterStream } from "@/api";

export default {
  props: {
    options: { type: Object, required: true },
  },
  data() {
    return {
      title: MSG_SESSION_RECORDING_ERROR_TITLE,
      text: MSG_SESSION_RECORDING_ERROR_TEXT,
    };
  },
  created() {
    const counts = getStorage("popUpCount");
    saveLog("session-recording-error", { counts });
  },
  methods: {
    async onClose() {
      deregisterStream(
        this.$store.state.user.session_id,
        this.$store.state.user.user_id,
      );
      this.$router.push({ name: ROUTE_PROFILE_HISTORY }).then(() => {
        this.$emit("close");
        return reloadPage();
      });
    },
  },
};
</script>
